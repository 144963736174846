import React from 'react';
import termsData from '../data/termsConditionsData.json';
import '../styles/termsConditions.css';
import Footer from './Footer';
import Header from './Header';

const termsConditions = () => {
  return (
    <div className="termsConditions">
    <Header/>
      <div className='termsContainer'>
        <div className='termsText'>
        <h2>TERMS AND CONDITIONS OF WEBSITE USE</h2>
       <p>Please read these Terms & Conditions ("Terms") carefully before using the [www.zinikus.com] website
(the "Service") operated by ZINIKUS. Your access to and use of the Service is conditioned upon your
acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others
who wish to access or use the Service. By accessing or using the Service, you agree to be bound by
these Terms. If you disagree with any part of the terms, then you may not access the Service.<br/><br/>
1. Use of Service: The content and materials available on the Service, including but not limited to text,
graphics, images, videos, and software, are the property of ZINIKUS and are protected by applicable
copyright and trademark laws. You may not use, reproduce, distribute, modify, or create derivative
works based upon such content without explicit written permission from ZINIKUS.<br/><br/>
2. Privacy: Your use of the Service is also governed by our Privacy Policy. Please review our Privacy
Policy to understand how we collect, use, and protect your personal information.<br/><br/>
3. User Accounts: If you create an account with us, you are responsible for maintaining the
confidentiality of your account and password and for restricting access to your computer. You agree to
accept responsibility for all activities that occur under your account.<br/><br/>
4. Limitations of Liability: ZINIKUS shall not be liable for any indirect, incidental, special, consequential,
or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any
loss of data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability
to access or use the Service.<br/><br/>
5. Third-Party Links: The Service may contain links to third-party websites or services that are not
owned or controlled by ZINIKUS. ZINIKUS has no control over and assumes no responsibility for, the
content, privacy policies, or practices of any third-party websites or services. You further acknowledge
and agree that ZINIKUS shall not be responsible or liable, directly or indirectly, for any damage or loss
caused or alleged to be caused by or in connection with the use of or reliance on any such content,
goods, or services available on or through any such websites or services.<br/><br/>
6. Changes to Terms & Conditions: We reserve the right, at our sole discretion, to modify or replace
these Terms at any time. If a revision is material, we will provide at least 30 days notice prior to any
new terms taking effect. What constitutes a material change will be determined at our sole discretion.<br/><br/>
7. Contact Us: If you have any questions about these Terms, please contact us at [adi@zinikus.com].
By accessing or using the Service, you acknowledge that you have read, understood, and agree to be
bound by these Terms.
These Terms & Conditions were generated by ZINIKUS.AI Private Limited and are effective as of 17.8.23<br/><br/><br/><br/><br/></p>
      <Footer />
    </div>

      </div>
      </div>
  );
};

export default termsConditions;
