import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import zeno1 from '../assets/homepage/ZENO1.jpg'
import zeno2 from '../assets/homepage/ZENO.mp4'
import '../styles/zini.css'
import '../styles/news.css';
import { MdKeyboardBackspace } from 'react-icons/md'
import { Link } from 'react-router-dom'

const Zeno = () => {
  return (
    <div>
    <div className='back backbtn' >
        <Link to={'/product'}>
          <MdKeyboardBackspace size={30} />
        </Link>
      </div>
    <div className="product-page">
      <div className="carousel-container" >
        <Carousel showThumbs={false} autoPlay infiniteLoop showArrows={false} showStatus={false}>
        <div className="product-section">
        <img src={zeno1} height={'300px'}/>
      </div>
      <div className="product-section carousel-containerimg">
        <video controls>
          <source src={zeno2} type="video/mp4" />
         </video>
      </div>
        </Carousel>
      </div><br/><br/>
      <div className="product-details">
        <h1 className="headtext zh">ZENO</h1>
        <p>ZENO THE LOBBY ROBOT</p><br/>
      </div>
      <div className="product-section">
        <h2 className="headtext" >Introduction</h2>
        <p>Introducing the ZENO Advanced Lobby Robot – Your Gateway to Elevated Customer Experiencet</p>
      </div><br/>
      <div className="product-section">
        <h2 className="headtext" >Advanced Features</h2>
        <p>
        1. Exceptional First Impressions: ZENO creates an unforgettable first impression for visitors,
demonstrating your commitment to innovation and customer service. Its sleek appearance and
advanced functionality set the stage for a memorable experience.
<br/>
2. 24/7 Customer Engagement: ZENO works tirelessly around the clock to assist customers, answer
inquiries, and provide information. This ensures that your business maintains a high level of service
even outside regular working hours.
<br/>
3. Cost-Effective Staffing: By automating routine tasks such as greeting guests, providing directions, or
delivering messages, ZENO allows your employees to focus on more complex, value-added tasks. This
can lead to significant cost savings in the long run.
<br/>
4. Customizable Branding: ZENO can be customized to match your brand's identity, creating a seamless
experience for customers. It can display your company logo, colors, and branding messages, reinforcing
your brand's presence in the lobby.
<br/>
5. Data Analytics: ZENO collects valuable data on customer interactions, enabling you to gain insights
into customer preferences and behavior. This data can be used to fine-tune your customer service
strategies and improve the overall customer experience.
<br/>
6. Multilingual Communication: ZENO is proficient in multiple languages, ensuring that it can
communicate effectively with a diverse range of customers and international visitors, making your
business more accessible and accommodating.<br/>
        </p>
      </div><br/>
      <div className="product-section">
        <h2 className="headtext" >Features</h2>
        <p>
        1. Intuitive Touchscreen Interface: ZENO's user-friendly touchscreen interface allows customers to
interact with it seamlessly, making it easy for visitors to get the information they need.
<br/>
2. Autonomous Navigation: Equipped with advanced sensors, ZENO navigates safely through your
lobby or front-of-house area, avoiding obstacles and ensuring efficient and secure movement.
<br/>
3. Voice Recognition: ZENO's state-of-the-art voice recognition system enables it to understand and
respond to spoken queries and commands, enhancing the customer experience.
<br/>
4. Facial Recognition: ZENO can recognize regular customers and personalize interactions, providing a
more tailored and engaging experience.
<br/>
5. High-Definition Display: Featuring a high-definition display, ZENO can showcase promotional videos,
advertisements, or important announcements to captivate and inform your audience.
       <br/>
       6. Remote Monitoring and Control: ZENO can be controlled and monitored remotely, allowing you to
intervene or update content as needed, even when you're not on-site. </p><br/>
      </div>
      <div className="product-section">
        <h2 className="headtext" >Why You Need ZENO</h2><br/>
        <p>In today's dynamic business environment, maintaining a competitive edge demands a commitment to innovation. ZENO isn't merely a robot; it represents a strategic investment in the future success of your company. Here's why your workplace can't afford to underestimate ZENO:
<br/>
1. Impressive First Impressions: ZENO's sleek design and advanced features create an unforgettable initial experience, showcasing your commitment to innovation and exceptional customer service.

<br/>
2. Continuous 24/7 Engagement: ZENO tirelessly assists customers, answers inquiries, and provides information around the clock, ensuring a high level of service outside regular working hours.


<br/>
3. Cost-Effective Operations: By automating routine tasks, ZENO allows your employees to focus on more valuable activities, leading to significant long-term cost savings.


<br/>
4. Data-Driven Insights and Multilingual Communication: ZENO collects valuable customer interaction data, providing insights into preferences and behaviors. Its multilingual proficiency ensures effective communication with a diverse range of customers and international visitors, making your business more accessible and accommodating.</p><br/>
      </div>
      <div className="product-section">
        <h2 className="headtext" >Conclusion</h2><br/>
        <p>In an era where customer experience is paramount, the ZENO Advanced Lobby Robot stands as an
invaluable asset to your business. With its capacity to make a lasting first impression, engage customers
around the clock, reduce operational costs, and provide valuable data insights, ZENO is more than a
robot – it's a strategic investment in your brand's success. Elevate your business, increase efficiency,
and take customer service to the next level with the ZENO Advanced Lobby Robot. Join the revolution
in customer engagement today.</p><br/>
      </div>
    </div>
    </div>
  )
}

export default Zeno