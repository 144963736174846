import React from 'react';

const darkTheme = {
  colors: {
    brand: {
      50: '#f7fafc',
    },
  },
};

export default darkTheme;
