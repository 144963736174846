import React, { useRef, useEffect } from 'react';
import { Image, HStack, Button } from '@chakra-ui/react';
import '../styles/about.css';
import { Link } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import pic1 from '../assets/homepage/pic1.jpeg';
import pic2 from '../assets/homepage/pic2.png';
import pic3 from '../assets/homepage/pic3.png';
import pic4 from '../assets/homepage/pic4.png';
import pic5 from '../assets/homepage/pic5.png';
import acheivement from '../assets/homepage/DIPP.png';
import skm1 from '../assets/homepage/SKM1.jpg';
import skm2 from '../assets/homepage/SKM2.jpg';
import skm3 from '../assets/homepage/SKM3.jpg';
import skm4 from '../assets/homepage/SKM4.jpg';
import { Carousel } from 'react-responsive-carousel';
// import zini4 from '../assets/homepage/zini4.mp4'

const About = () => {
  // const observer = new IntersectionObserver(entries => {
  //   entries.forEach(entry => {
  //     const s1 = entry.target.querySelector('.s1');
  //     const s2 = entry.target.querySelector('.s2');
  //     const s3 = entry.target.querySelector('.s3');

  //     if (entry.isIntersecting) {
  //       s1.classList.add('faltuHeading-animation');
  //       s2.classList.add('faltuText-animation');
  //       s3.classList.add('imgs-animation');
  //     return; // if we added the className, exit the function
  //     }

  //     // We're not intersecting, so remove the className!
  //     // square.classList.remove('square-animation');
  //   });
  // });
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  // observer.observe(document.querySelector('.lowerAboutUs'));
  const observerRef = useRef(null);

  useEffect(() => {
    // Create the Intersection Observer instance
    observerRef.current = new IntersectionObserver(entries => {
      // Loop over the entries
      entries.forEach(entry => {
        // If the element is visible
        if (entry.isIntersecting) {
          // Add the animation className
          entry.target.classList.add('faltuHeading-animation');
          entry.target.classList.add('faltuText-animation');
          entry.target.classList.add('imgs-animation');
        }
      });
    });

    // Observe the elements
    const faltuHeadingElement = document.querySelector('.faltuHeading');
    const faltuTextElement = document.querySelector('.faltuText');
    const imgsElement = document.querySelector('.imgs');

    if (faltuHeadingElement) {
      observerRef.current.observe(faltuHeadingElement);
    }
    if (faltuTextElement) {
      observerRef.current.observe(faltuTextElement);
    }
    if (imgsElement) {
      observerRef.current.observe(imgsElement);
    }

    // Clean up the observer when the component unmounts
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return (
    <div className="mainAbout">
      <div className="about zenoAbout" id="about">
        <div className="lowerSpaceMaker"></div>
        <div className="midaboutus">
          {/* <div className="headingtopabout">
            <h2>Here's what we do...</h2>

            <button className="headaboutbtn">
              <Link to={'/product'}>View More</Link>
            </button>
          </div>

          <div className="videocarousel">
            <video
              autoPlay
              controls
              muted
              // poster={thumbnail}
              width="640"
              height="360"
              className="videocarousel"
            >
              <source src={zoro3} type="video/mp4" />
            </video>
          </div> */}
          <div className="landscape-card">
            <div className="landscape-card-image-zeno"></div>
            {/* Zeno product */}
            <div
              className="landscape-card-content"
              style={{ marginTop: '-3rem' }}
            >
              <h2 className="landscape-card-title-zeno">
                {/* <Image src={zenoIcon} /> */}
              </h2>
              {/* <h3>Lorem ipsum dolor sit amet.</h3> */}
              <p style={{ marginTop: '-4rem' }}>
                Introducing ZENO, the robot revolutionizing customer engagement!
                With ZENO, gone are the days of lackluster lobby experiences.
                Say hello to a robotic marvel designed to dazzle your clientele
                while streamlining operations.
                <br />
                <br /> ZENO isn't just a robot; it's your brand's ultimate
                wingman, 24/7. From making that jaw-dropping first impression to
                crunching valuable data insights, ZENO does it all. So why
                settle for mediocrity when you can elevate your business with
                ZENO?
              </p>
              <button className="headaboutbtn2" onClick={scrollToTop}>
                <Link to={'/zeno'}>View More</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Zini product */}
      <div
        className="about ziniAbout"
        id="about"
        style={{ backgroundColor: 'black' }}
      >
        <div className="midaboutus">
          <div
            className="landscape-card"
            // style={{
            //   backgroundColor: 'black',
            //   width: '110vw',
            //   marginLeft: '-16.75%',
            //   padding: '5% 10% 5% 10%',
            // }}
          >
            <div
              className="landscape-card-content"
              style={{ paddingLeft: '0', paddingRight: '40px', color: 'white' }}
            >
              <h2 className="landscape-card-title-zini">
                {/* <Image src={zenoIcon} /> */}
              </h2>
              {/* <h3>Lorem ipsum dolor sit amet.</h3> */}
              <p style={{ marginTop: '-4rem' }}>
                Step into tomorrow's workplace with ZINI - The Reception Robot,
                your future colleague with a metallic smile and a knack for
                efficient hellos.
                <br />
                <br /> Say goodbye to mundane reception duties and hello to
                streamlined sophistication. ZINI isn't just a receptionist; it's
                the silicon soul of workplace progress, ready to dazzle visitors
                and streamline operations with a wink and a whirr.
              </p>
              <button className="headaboutbtn2" onClick={scrollToTop}>
                <Link to={'/zini'}>View More</Link>
              </button>
            </div>

            <div className="landscape-card-image-zini"></div>
          </div>
        </div>
      </div>

      {/* Zoro product */}
      <div className="about" id="about">
        <div className="midaboutus">
          <div className="landscape-card">
            <div className="landscape-card-image-zoro"></div>
            <div
              className="landscape-card-content"
              style={{ marginTop: '-3rem' }}
            >
              <h2 className="landscape-card-title-zoro">
                {/* <Image src={zenoIcon} /> */}
              </h2>
              {/* <h3>Lorem ipsum dolor sit amet.</h3> */}
              <p style={{ marginTop: '-4rem' }}>
                ZORO isn't just a telepresence; it's an embodiment of progress,
                sophistication, and streamlined operations. Elevate your
                workplace experience, captivate visitors, and embark on a
                journey of innovation with ZORO by your side.
                <br />
                <br /> Contact us today to learn how ZORO can redefine your
                workplace dynamics and become the cornerstone of your business
                success.
              </p>
              <button className="headaboutbtn2" onClick={scrollToTop}>
                <Link to={'/zoro'}>View More</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="carousalAbout"
        style={{ backgroundColor: 'black', padding: '1rem 0rem 4rem 0rem' }}
      >
        <div style={{ backgroundColor: 'black' }}>
          <h2 className="headingabout">
            <a>Photo Gallery</a>
          </h2>
          <Carousel
            className="aboutCar"
            autoPlay={true}
            interval={2000}
            infiniteLoop={true}
            showArrows={true}
            showStatus={false}
            showThumbs={false}
          >
            <div
              className="divPic"
              style={{
                backgroundImage: `url(${pic1})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              {/* <img src={pic1} /> */}
            </div>
            {/* <div className='divPic secondPic'>
                <img className='secimgabout' src={pic2}/>
                </div> */}
            <div
              className="divPic"
              style={{
                backgroundImage: `url(${pic3})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              {/* <img src={pic3} /> */}
            </div>
            <div
              className="divPic"
              style={{
                backgroundImage: `url(${pic4})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              {/* <img src={pic4} /> */}
            </div>
            <div
              className="divPic"
              style={{
                backgroundImage: `url(${pic5})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              {/* <img src={pic5} /> */}
            </div>
            <div
              className="divPic"
              style={{
                backgroundImage: `url(${skm1})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              {/* <img src={pic5} /> */}
            </div>
            <div
              className="divPic"
              style={{
                backgroundImage: `url(${skm2})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              {/* <img src={pic5} /> */}
            </div>
            <div
              className="divPic"
              style={{
                backgroundImage: `url(${skm3})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              {/* <img src={pic5} /> */}
            </div>
            <div
              className="divPic"
              style={{
                backgroundImage: `url(${skm4})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              {/* <img src={pic5} /> */}
            </div>

            <div
              className="divPic"
              style={{
                backgroundImage: `url(${acheivement})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              {/* <img src={acheivement} /> */}
            </div>
          </Carousel>
        </div>
      </div>
      <div className="upperAbout" id="upperAbout">
        <div className="whiteLowerSpaceMaker"></div>
        {/* <div className='abouth2'><h2>ABOUT US</h2></div> */}
        <div className="h22">
          <h2>
            <a>ABOUT US</a>
          </h2>
        </div>
        {/* <div className="whiteLowerSpaceMaker"></div> */}
        <div>
          <p className="aboutp">
            We are dedicated to creating cutting-edge technology that pushes the
            boundaries of what's possible in the world of automation. Our team
            is composed of experts in various fields, including robotics,
            machine learning, computer science, and engineering. Together, we're
            creating a future where robots can perform complex tasks with ease,
            making our lives easier and more efficient.
            <br />
            <br />
            At our company, we believe in constant innovation and collaboration.
            We're always looking for new ways to improve our products and
            services and are open to working with partners who share our vision.
            We're excited to be at the forefront of this exciting field and look
            forward to pushing the boundaries of what's possible in the world of
            A.I. and robotics.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
